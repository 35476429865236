import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER } from './actionTypes';
import { loginSuccess, apiError } from './actions';
import { setAuthenticatedUser } from '../../actions';

import authenticationService from '../../../services/authenticationService';
import authorizationHelper from '../../../helpers/authorizationHelper';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(authenticationService.logIn, { email: user.email, password: user.password });

    // Only allow admins login
    if (!authorizationHelper.isAtLeastAdmin(response.user?.role)) {
      // eslint-disable-next-line
      throw 'Unauthorized login';
    }
    const { authToken } = response;
    authenticationService.setToken(authToken);
    authenticationService.setLoggedInUser(response.user);
    yield put(loginSuccess(response));
    yield put(setAuthenticatedUser({ user: response.user, isAuthenticated: true, authToken }));
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin)]);
}

export default authSaga;
