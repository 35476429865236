import axios from 'axios';

import API from './API';
import errorHandler from '../helpers/errorHandler';

export default {
  getSignedUrl(data) {
    return API.post('/v1/upload', data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  uploadPhoto(url, data, options) {
    return axios
      .put(url, data, options)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  uploadDocument(url, data) {
    console.log({ url, data });
    return API.post(`/v1/upload/${url}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data)
      .catch(errorHandler.handleAPIError);
  },
};
