import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';
// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import Layout from './components/Layout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import { setAuthenticatedUser } from './store/actions';
import authenticationService from './services/authenticationService';

// TODO delete all references once auth is completely setup
// import fakeBackend from './helpers/AuthType/fakeBackend';

// // Activating fake backend
// fakeBackend();
function App(props) {
  const { setAuthenticatedUser } = props;
  const user = authenticationService.getLoggedInUser();
  const authToken = authenticationService.restoreToken();
  useEffect(() => {
    if (user && authToken) {
      setAuthenticatedUser({ user, isAuthenticated: true, authToken });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const NonAuthmiddleware = ({ path, component: Component, layout: Layout }) => (
    <Route
      path={path}
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware path={route.path} layout={Layout} component={route.component} key={idx} />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};
const mapDispatchToProps = { setAuthenticatedUser };

export default connect(mapStateToProps, mapDispatchToProps)(App);
