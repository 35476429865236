import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Pagination from '../../../components/Common/Pagination';
import ConfirmDelete from '../../../components/Common/ConfirmDelete';

import founderService from '../../../services/founderService';
import { getAge } from '../../../helpers/dateHelper';

function Founders(props) {
  const [founders, setFounders] = useState([]);
  const [founder, setFounder] = useState({});
  const [query, setQuery] = useState('');
  const [paginationData, setPaginationData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [confirmDelete, setconfirmDelete] = useState(false);

  async function getFounders(query, limit, page) {
    try {
      setApiLoading(true);
      const response = (await founderService.getFounders({ query, limit, page })).data;
      setFounders(response.founders);
      setPaginationData(response.metaData);
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error Fetching Founders');
    }
  }
  useEffect(() => {
    getFounders(query, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationClick = (page) => {
    getFounders(query, limit, page);
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setLimit(value);
    getFounders(query, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getFounders(query, limit);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleonDelete = (founder) => {
    setFounder(founder);
    setconfirmDelete(true);
  };

  const handleDelete = async (event) => {
    try {
      setApiLoading(true);
      await founderService.deleteFounder(founder.id);
      toast.success(`Founder ${founder.firstName} ${founder.lastName} deleted successfully`);
      setApiLoading(false);
      getFounders(query, limit);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error Fetching Founders');
    }
    setconfirmDelete(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Founders" subSection="All Founders" />
          {confirmDelete && (
            <ConfirmDelete
              confirmBtnText={`Yes, Delete Founder`}
              onConfirm={handleDelete}
              onCancel={() => setconfirmDelete(false)}
              message={`You are about to Delete founder ${founder.firstName} ${founder.lastName}`}
            />
          )}
          <Row>
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="form-group m-0">
                <div className="input-group">
                  <input
                    type="search"
                    value={query}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Search founders ..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">
                      <i className="mdi mdi-magnify"></i>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <label htmlFor="search" className="col-form-label" style={{ margin: '0 5px 0 10px' }}>
                      Limit
                    </label>
                    <select id="search" value={limit} onChange={handleLimitChange} className="form-control">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-2 text-center">
                    {apiLoading && <i className="bx bx-loader bx-spin  bx-sm"></i>}
                  </div>
                  {paginationData?.pageCount > 0 && (
                    <Row>
                      <Col lg="12">
                        <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                      </Col>
                    </Row>
                  )}
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Avatar</th>
                          <th scope="col">FirstName</th>
                          <th scope="col">LastName</th>
                          <th scope="col">Email</th>
                          <th scope="col">Age</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {founders?.length > 0 &&
                          founders.map((user) => (
                            <tr key={user.id}>
                              <td>
                                <Link to={`/founders/edit/${user.id}`} id={'profile' + user.id}>
                                  <span>{user.id}</span>
                                </Link>
                              </td>
                              <td>
                                <Link to={`/founders/edit/${user.id}`} id={'profile' + user.id}>
                                  {!user.imageUrl ? (
                                    <div className="avatar-xs">
                                      <span className="avatar-title rounded-circle">
                                        {user.firstName.charAt(0).toUpperCase()}
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      <img className="rounded-circle avatar-xs" src={user.imageUrl} alt="" />
                                    </div>
                                  )}
                                </Link>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{user.firstName}</h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{user.lastName}</h5>
                              </td>
                              <td>{user.email}</td>
                              <td>{getAge(user.birthDate)}</td>
                              <td>
                                <ul className="list-inline font-size-20 contact-links mb-0">
                                  <li className="list-inline-item px-2">
                                    <Link to={`/founders/edit/${user.id}`} id={'profile' + user.id}>
                                      <i className="bx bx-edit"></i>
                                      <UncontrolledTooltip placement="top" target={'profile' + user.id}>
                                        Edit
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item px-2">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a onClick={() => handleonDelete(user)}>
                                      <i className="bx bx-trash"></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        {founders?.length === 0 && !apiLoading && (
                          <tr>
                            <th colSpan="8" className="text-center">
                              no Founders found
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {paginationData?.pageCount > 0 && (
                    <Row>
                      <Col lg="12">
                        <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Founders);
