import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  CircularProgress,
  TextField,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { Row, Col, Form } from 'reactstrap';

import { formatDate } from '../../../helpers/dateHelper';
import companyService from '../../../services/companyService';
import investorService from '../../../services/investorService';

function FundingRoundForm(props) {
  const [investorOptions, setInvestorOptions] = React.useState([]);
  const [companyOptions, setCompanyOptions] = React.useState([]);

  const { onSubmit, inputValues, error, onChange, apiLoading, isEditMode } = props;
  const { title, amount, type, investors, dateFunded, companyId } = inputValues;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    onChange({ name, value, type, checked });
  };

  // const updateImageUrl = (url) => {
  //   onImageUrlChange(url);
  // };

  // const setImageInputError = (error) => {
  //   onImageError(error);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  const getRequiredData = async () => {
    const investorsPromise = investorService.getInvestors({ all: true });
    const companiesPromise = companyService.getCompanies({ all: true });

    const [investorResponse, companiesResponse] = await Promise.all([investorsPromise, companiesPromise]);
    const { investors } = investorResponse.data;
    const { companies } = companiesResponse.data;
    setInvestorOptions(investors);
    setCompanyOptions(companies);
  };

  useEffect(() => {
    getRequiredData();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      {companyOptions.length > 0 && (
        <>
          <Row>
            <Col md="6">
              <div className="form-group">
                <TextField
                  id="title"
                  name="title"
                  type="text"
                  label="Title"
                  value={title}
                  helperText={error?.errors?.title}
                  placeholder="Flutterwave Series A round"
                  error={!!error?.errors?.title}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  required
                />
              </div>
            </Col>

            <Col md="6">
              <div className="form-group">
                <TextField
                  id="type"
                  name="type"
                  type="text"
                  label="Type"
                  value={type}
                  helperText={error?.errors?.type}
                  error={!!error?.errors?.type}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  required
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="form-group">
                <FormControl variant="outlined" fullWidth error={error?.errors?.investors}>
                  <InputLabel id="companyId">Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={companyId}
                    name="companyId"
                    onChange={handleChange}
                    label="Company"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {companyOptions?.length > 0 &&
                      companyOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {error?.errors?.investors && <FormHelperText>{error?.errors?.investors}</FormHelperText>}
                </FormControl>
              </div>
            </Col>

            <Col md="6">
              <div className="form-group">
                <TextField
                  id="amount"
                  name="amount"
                  type="number"
                  label="Amount"
                  value={amount}
                  helperText={error?.errors?.amount}
                  error={!!error?.errors?.amount}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="form-group">
                <TextField
                  id="dateFunded"
                  name="dateFunded"
                  type="date"
                  label="Funding Date"
                  value={dateFunded ? formatDate(new Date(dateFunded), 'yyyy-MM-dd') : dateFunded}
                  helperText={error?.errors?.dateFunded}
                  error={!!error?.errors?.dateFunded}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  required
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <FormControl variant="outlined" fullWidth error={error?.errors?.investors}>
                  <InputLabel id="investors">Investors</InputLabel>
                  <Select
                    label="investors"
                    id="investors"
                    multiple
                    name="investors"
                    value={investors}
                    onChange={handleChange}
                  >
                    <MenuItem value={[]}>
                      <em>None</em>
                    </MenuItem>
                    {investorOptions?.length > 0 &&
                      investorOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {error?.errors?.investors && <FormHelperText>{error?.errors?.investors}</FormHelperText>}
                </FormControl>
              </div>
            </Col>
          </Row>

          <div className="mt-3">
            <Col md="4">
              <Button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                variant="contained"
                color="primary"
                disabled={apiLoading}
              >
                {isEditMode ? 'Edit Funding Round' : 'Add Funding Round'}
                {apiLoading && <CircularProgress size={30} />}
              </Button>
            </Col>
          </div>
        </>
      )}
    </Form>
  );
}

export default FundingRoundForm;
