import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'reactstrap';

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  deleteAuthenticatedUser,
} from '../../store/actions';
// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import axiosInstance from '../../services/API';
import authenticationService from '../../services/authenticationService';
import config from '../../config';

const alertStyle = {
  position: 'absolute',
  zIndex: '9999',
  bottom: 0,
  left: '60%',
  transform: 'translate(-50%, -50%)',
  // bottom: 40,
};
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProd: true,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }

    const { history, authToken, deleteAuthenticatedUser } = this.props;
    // intercept every network request if the user is authenticated
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        // if it's a 401 error. i.e token has expired
        if (error.response && error.response.status === 401 && !!authToken) {
          // automatically log the user out when the authentication token expires
          toast.error('Session timed out');
          authenticationService.logOut();
          deleteAuthenticatedUser();
          history.push('/login');
        } else {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      },
    );
    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentPage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title = currentPage + ' | Yabalytics Dashboard';
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }

    if (/api.yabalytics/i.test(config.baseApiURL)) {
      this.setState({ isProd: true });
    } else {
      this.setState({ isProd: false });
    }
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === 'default') {
      this.props.changeSidebarType('condensed', this.state.isMobile);
    } else if (this.props.leftSideBarType === 'condensed') {
      this.props.changeSidebarType('default', this.state.isMobile);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />
          <div className="main-content">
            {this.state.isProd && (
              <Alert color="danger" style={alertStyle}>
                Warning: You are currently viewing production data
              </Alert>
            )}
            {!this.state.isProd && (
              <Alert color="info" style={alertStyle}>
                You are currently viewing staging data
              </Alert>
            )}
            {this.props.children}
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { authenticatedUser, authToken } = state.User;
  return {
    ...state.Layout,
    authenticatedUser,
    authToken,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  deleteAuthenticatedUser,
})(withRouter(Layout));
