import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Alert } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import founderService from '../../../services/founderService';
import validationHelper from '../../../helpers/validationHelper';
import FounderForm from '../../../components/Secondary/FounderForm';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  imageUrl: '',
  companies: [],
  previousCompanies: [],
  birthDate: '',
  isVerified: false,
  websiteURL: '',
  twitterURL: '',
  instagramURL: '',
  linkedInURL: '',
  mediumURL: '',
  substackURL: '',
};

function EditFounder(props) {
  const { history } = props;
  const { founderId } = useParams();

  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userInput, setUserInput] = useState(initialState);

  async function getFounder(id) {
    setApiLoading(true);
    try {
      const response = (await founderService.getFounder(id)).data;
      const { founder } = response;
      const previousCompanies = founder.previousCompanies ? founder.previousCompanies.map((comp) => comp.id) : [];
      const companies = founder.companies ? founder.companies.map((comp) => comp.id) : [];
      setUserInput({
        ...founder,
        companies,
        previousCompanies,
      });
      setInitialLoading(false);
    } catch (err) {
      setInitialLoading(false);
      setError({ message: err.message || 'Error Fetching Users' });
    }
    setApiLoading(false);
  }

  useEffect(() => {
    getFounder(founderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [founderId]);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await founderService.updateFounder(founderId, trimmedInput);
      toast.success(response.message);
      history.push('/founders');
    } catch (error) {
      setError(error);
      toast.error(error.message || 'error editing founder');
    }
    setApiLoading(false);
  }

  const setImageInputError = (error) => {
    setError({ errors: { imageUrl: error } });
  };

  const updateImageUrl = (url) => {
    setUserInput((prevValue) => ({ ...prevValue, imageUrl: url }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Founders" subSection="Edit Founder" />
          {error.message && (
            <Alert color="danger" style={{ marginTop: '13px' }}>
              {error.message}
            </Alert>
          )}
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div className="mb-2 text-center">
                    {(apiLoading || initialLoading) && <i className="bx bx-loader bx-spin  bx-sm"></i>}
                  </div>
                  <div>
                    {!initialLoading && (
                      <FounderForm
                        isEditMode={true}
                        inputValues={userInput}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        error={error}
                        onImageUrlChange={updateImageUrl}
                        apiLoading={apiLoading}
                        onImageError={setImageInputError}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(EditFounder);
