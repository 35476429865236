import API from './API';
import errorHandler from '../helpers/errorHandler';

export default {
  getLoggedInUser() {
    const user = localStorage.getItem('authUser');
    if (user) return JSON.parse(user);
    return null;
  },
  removeLoggedInUser() {
    localStorage.removeItem('authUser');
  },
  setLoggedInUser(user) {
    if (!user) return;
    localStorage.setItem('authUser', JSON.stringify(user));
  },
  isUserAuthenticated() {
    return this.getLoggedInUser() !== null;
  },
  getToken() {
    const token = localStorage.getItem('authToken');
    if (token) {
      return JSON.parse(token);
    }
    return null;
  },
  setToken(authToken) {
    if (authToken) {
      API.defaults.headers.common['authorization'] = `Bearer ${authToken}`;
      localStorage.setItem('authToken', JSON.stringify(authToken));
    }
  },
  restoreToken() {
    const authToken = this.getToken();
    if (authToken) {
      API.defaults.headers.common['authorization'] = `Bearer ${authToken}`;
      return authToken;
    }
    return null;
  },
  deleteToken() {
    delete API.defaults.headers.common['authorization'];
    localStorage.removeItem('authToken');
  },
  logIn(credentials) {
    return API.post('/v1/auth/login', credentials)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        errorHandler.handleAPIError(err);
      });
  },
  signUp(credentials) {
    return API.post('/v1/auth/signup', credentials)
      .then((response) => {
        return response.data.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  requestPasswordReset(credentials) {
    return API.post('/v1/account/forgot', credentials)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  resetPassword(credtials, resetToken) {
    return API.post(`/v1/account/reset/${resetToken}`, credtials)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  logOut() {
    this.deleteToken();
    this.removeLoggedInUser();
  },
};
