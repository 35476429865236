import React, { useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// actions
import { loginUser } from '../../store/actions';

// import images
import formBackgroundImage from '../../assets/images/profile-img.png';
import logo from '../../assets/images/icon-light-background.svg';

function Login(props) {
  const { loading, history, loginUser, error } = props;

  // handleValidSubmit
  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
  });

  // handle input change with keyboard press
  function handleChange(event) {
    const { name, value } = event.target;
    setUserInput((prevValue) => ({ ...prevValue, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    loginUser(userInput, history);
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Dashboard.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={formBackgroundImage} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="55" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      {error && <Alert color="danger">{error.message || error}</Alert>}

                      <div className="form-group">
                        <TextField
                          id="email"
                          name="email"
                          type="email"
                          label="Email"
                          value={userInput.email}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          id="password"
                          name="password"
                          label="Password"
                          value={userInput.password}
                          type="password"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </div>

                      <div className="mt-3">
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading || !userInput.email || !userInput.password}
                        >
                          Login
                          {loading && <CircularProgress size={30} />}
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-primary">
                          <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Yabalytics</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

const mapDispatchToProps = { loginUser };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
