import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import ResetPassword from '../pages/Authentication/ResetPassword';

// Dashboard
import Dashboard from '../pages/Dashboard/index';
import UsersPage from '../pages/Dashboard/Users';
import Companies from '../pages/Dashboard/Companies/Index';
import AddCompany from '../pages/Dashboard/Companies/AddCompany';
import EditCompany from '../pages/Dashboard/Companies/EditCompany';
import Founders from '../pages/Dashboard/Founders/Index';
import AddFounder from '../pages/Dashboard/Founders/AddFounder';
import EditFounder from '../pages/Dashboard/Founders/EditFounder';
import Investors from '../pages/Dashboard/Investors/Index';
import AddInvestor from '../pages/Dashboard/Investors/AddInvestor';
import EditInvestor from '../pages/Dashboard/Investors/EditInvestor';
import FundingRounds from '../pages/Dashboard/FundingRound/Index';
import AddFundingRound from '../pages/Dashboard/FundingRound/AddFundingRound';
import EditFundingRound from '../pages/Dashboard/FundingRound/EditFundingRound';
import BulkUpload from '../pages/Dashboard/BulkUpload';
// import EstatesPage from '../pages/Dashboard/Estates';

const userRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/users', component: UsersPage },

  { path: '/startups/add', component: AddCompany },
  { path: '/startups/edit/:companyId', component: EditCompany },
  { path: '/startups', component: Companies },

  { path: '/founders/add', component: AddFounder },
  { path: '/founders/edit/:founderId', component: EditFounder },
  { path: '/founders', component: Founders },

  { path: '/investors/add', component: AddInvestor },
  { path: '/investors/edit/:investorId', component: EditInvestor },
  { path: '/investors', component: Investors },

  { path: '/funding-rounds/add', component: AddFundingRound },
  { path: '/funding-rounds/edit/:fundingRoundId', component: EditFundingRound },
  { path: '/funding-rounds', component: FundingRounds },

  { path: '/bulk-upload', component: BulkUpload },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/reset-password/:resetToken', component: ResetPassword },
];

export { userRoutes, authRoutes };
