import React, { useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// import images
import formBackgroundImage from '../../assets/images/profile-img.png';
import logo from '../../assets/images/icon-light-background.svg';
import authenticationService from '../../services/authenticationService';
import validationHelper from '../../helpers/validationHelper';

const initialState = {
  email: '',
  password: '',
  confirmPassword: '',
};

function ResetPasswordPage(props) {
  const { resetToken } = useParams();
  const [userInput, setUserInput] = useState(initialState);
  const [apiLoading, setApiLoading] = useState(false);
  const [successMessage, setMessage] = useState('');
  const [error, setError] = useState({});

  function handleChange(event) {
    const { name, value } = event.target;
    setUserInput((prevValue) => ({ ...prevValue, [name]: value }));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setApiLoading(true);
      const trimmedInput = validationHelper.trimValues(userInput);
      const response = await authenticationService.resetPassword(trimmedInput, resetToken);
      setMessage(response.message);
      setUserInput(initialState);
      setError({});
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      setMessage('');
      setError({
        message: err.message || err,
        ...err?.errors,
      });
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Reset Your Password</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={formBackgroundImage} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="50" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error.message && (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {error.message}
                      </Alert>
                    )}
                    {successMessage && (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {successMessage}
                      </Alert>
                    )}

                    <form className="form-horizontal mt-4" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <TextField
                          id="email"
                          name="email"
                          type="email"
                          label="Email"
                          helperText={error?.email}
                          error={!!error?.email}
                          value={userInput.email}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="password"
                          name="password"
                          label="Password"
                          helperText={error?.password}
                          error={!!error?.password}
                          value={userInput.password}
                          type="password"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="password"
                          name="confirmPassword"
                          label="Confirm Password"
                          helperText={error?.confirmPassword}
                          error={!!error?.confirmPassword}
                          value={userInput.confirmPassword}
                          type="password"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <Button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            color="primary"
                            disabled={apiLoading || !userInput.email || !userInput.confirmPassword}
                            variant="contained"
                          >
                            Reset
                            {apiLoading && <CircularProgress size={30} />}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{' '}
                </p>
                <p>© {new Date().getFullYear()} Yabalytics</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ResetPasswordPage;
