import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import fundingRoundService from '../../../services/fundingRoundService';
import validationHelper from '../../../helpers/validationHelper';
import FundingRoundForm from './FundingRoundForm';

const initialState = {
  title: '',
  companyId: '',
  type: '',
  amount: '',
  investors: [],
  dateFunded: '',
};

function EditFundingRound(props) {
  const { history } = props;
  const { fundingRoundId } = useParams();

  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [userInput, setUserInput] = useState(initialState);
  const [initialLoading, setInitialLoading] = useState(true);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await fundingRoundService.updateFundingRound(fundingRoundId, trimmedInput);
      setUserInput({ ...initialState });
      toast.success(response.message);
      history.push('/funding-rounds');
    } catch (error) {
      error?.message && toast.success(error.message);
      setError(error);
    }
    setApiLoading(false);
  }

  async function getInvestor(id) {
    setUserInput(true);
    try {
      const response = (await fundingRoundService.getFundingRound(id)).data;
      const { fundingRound } = response;
      const investors = fundingRound.investors ? fundingRound.investors.map((fr) => fr.id) : [];
      setUserInput({
        ...fundingRound,
        investors,
        companyId: fundingRound.company,
      });
    } catch (err) {
      setError({ message: err.message || 'Error getting funding round' });
    }
    setInitialLoading(false);
  }

  useEffect(() => {
    getInvestor(fundingRoundId);
  }, [fundingRoundId]);

  // const setImageInputError = (error) => {
  //   setError({ errors: { logoUrl: error } });
  // };

  // const updateImageUrl = (url) => {
  //   setUserInput((prevValue) => ({ ...prevValue, logoUrl: url }));
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Funding Rounds" subSection="Edit Funding Rounds" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div className="mb-2 text-center">
                    {(apiLoading || initialLoading) && <i className="bx bx-loader bx-spin  bx-sm"></i>}
                  </div>
                  <div>
                    {!initialLoading && (
                      <FundingRoundForm
                        inputValues={userInput}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        error={error}
                        apiLoading={apiLoading}
                        isEditMode={true}
                        // onImageUrlChange={updateImageUrl}
                        // onImageError={setImageInputError}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(EditFundingRound);
