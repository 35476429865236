import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import companyService from '../../../services/companyService';
import validationHelper from '../../../helpers/validationHelper';
import CompanyForm from './CompanyForm';

const initialState = {
  name: '',
  isNonProfit: false,
  sector: '',
  logoUrl: '',
  isVerified: false,
  numberOfEmployees: '',
  numberOfUsers: '',
  isFunded: false,
  fundingRounds: [],
  founders: [],
  investors: [],
  dateFounded: '',
  isActive: false,
  isPubliclyTraded: false,
  location: '',
  websiteURL: '',
  facebookURL: '',
  twitterURL: '',
  instagramURL: '',
  linkedInURL: '',
  mediumURL: '',
  substackURL: '',
};

function AddCompany(props) {
  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [userInput, setUserInput] = useState(initialState);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await companyService.addCompany(trimmedInput);
      setUserInput({ ...initialState });
      toast.success(response.message);
    } catch (error) {
      setError(error);
      toast.error(error.message);
    }
    setApiLoading(false);
  }

  const setImageInputError = (error) => {
    setError({ errors: { logoUrl: error } });
  };

  const updateImageUrl = (url) => {
    setUserInput((prevValue) => ({ ...prevValue, logoUrl: url }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Startups" subSection="Add Startup" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div>
                    <CompanyForm
                      inputValues={userInput}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      error={error}
                      onImageUrlChange={updateImageUrl}
                      apiLoading={apiLoading}
                      onImageError={setImageInputError}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddCompany;
