import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
  TextField,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { Row, Col, Form } from 'reactstrap';

import PhotoUpload from '../../../components/Common/PhotoUpload';
import Switch from '../../../components/Common/Switch';
import companyService from '../../../services/companyService';
// import investorService from '../../../services/investorService';
import validationHelper from '../../../helpers/validationHelper';

import defaultAvatar from '../../../assets/images/defaults/startup.png';

const investorTypes = Object.entries(validationHelper.investorTypeMapper);
const fundingTypes = Object.entries(validationHelper.fundingStagesMapper);
function InvestorForm(props) {
  const [companyOptions, setCompanyOptions] = React.useState([]);
  // const [investorOptions, setInvestorOptions] = React.useState([]);

  const { onSubmit, inputValues, onImageUrlChange, error, onImageError, onChange, apiLoading, isEditMode } = props;
  const {
    name,
    type,
    fundingCapacity,
    fundingStages,
    // numberOfExits,
    logoUrl,
    isVerified,
    location,
    companiesFunded,
  } = inputValues;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    onChange({ name, value, type, checked });
  };

  const handleFundingStageChange = (event) => {
    const { name, value } = event.target;
    const formatteValue = value.join(',');
    onChange({ name, value: formatteValue });
  };

  const updateImageUrl = (url) => {
    onImageUrlChange(url);
  };

  const setImageInputError = (error) => {
    onImageError(error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  const getRequiredData = async (query) => {
    const companyResponse = await companyService.getCompanies({ all: true });
    // const investorPromise = investorService.getInvestors({ limit: 50 });

    // const [companyResponse, investorResponse] = await Promise.all([companyPromise, investorPromise]);
    const { companies } = companyResponse.data;
    // const { investors } = investorResponse.data;
    setCompanyOptions(companies);
    // setInvestorOptions(investors);
  };

  useEffect(() => {
    getRequiredData();
  }, []);

  const splitFundingStages = fundingStages ? fundingStages.split(',') : [];

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <div className="form-group">
            <PhotoUpload
              uploadType="investor"
              imageUrl={logoUrl}
              defaultAvatar={defaultAvatar}
              name={name}
              updateImageUrl={updateImageUrl}
              onError={setImageInputError}
              errorMessage={error?.errors?.logoUrl}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="name"
              name="name"
              type="text"
              label="Name"
              value={name}
              helperText={error?.errors?.name}
              error={!!error?.errors?.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
        <Col md="6">
          <FormControl variant="outlined" fullWidth error={error?.errors?.type}>
            <InputLabel id="type">Investor Type</InputLabel>
            <Select
              label="type"
              id="type"
              name="type"
              value={type}
              onChange={handleChange}
              // renderValue={(value) => `⚠️  - ${value}`}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {investorTypes.map((entry) => (
                <MenuItem key={entry[0]} value={entry[0]}>
                  {entry[1]}
                </MenuItem>
              ))}
            </Select>
            {error?.errors?.type && <FormHelperText>{error?.errors?.type}</FormHelperText>}
          </FormControl>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <FormControl variant="outlined" fullWidth error={error?.errors?.fundingStages}>
              <InputLabel id="fundingStages">Funding Stages</InputLabel>
              <Select
                label="Funding Stages"
                id="fundingStages"
                name="fundingStages"
                value={splitFundingStages}
                onChange={handleFundingStageChange}
                multiple
                // renderValue={(value) => `⚠️  - ${value}`}
              >
                <MenuItem value={splitFundingStages.length === 0}>
                  <em>None</em>
                </MenuItem>
                {fundingTypes.map((entry) => (
                  <MenuItem key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                ))}
              </Select>
              {error?.errors?.fundingStages && <FormHelperText>{error?.errors.fundingStages}</FormHelperText>}
            </FormControl>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="fundingCapacity"
              name="fundingCapacity"
              type="number"
              label="Funding Capacity"
              value={fundingCapacity}
              helperText={error?.errors?.fundingCapacity}
              error={!!error?.errors?.fundingCapacity}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormControl variant="outlined" fullWidth error={error?.errors?.companiesFunded}>
            <InputLabel id="companiesFunded">Companies Funded</InputLabel>
            <Select
              label="companiesFunded"
              id="companiesFunded"
              multiple
              name="companiesFunded"
              value={companiesFunded}
              onChange={handleChange}
            >
              <MenuItem value={[]}>
                <em>None</em>
              </MenuItem>
              {companyOptions.length > 0 &&
                companyOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
            {error?.errors && error?.errors['companiesFunded[0]'] && (
              <FormHelperText>{error?.errors['companiesFunded[0]']}</FormHelperText>
            )}
          </FormControl>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="location"
              name="location"
              type="text"
              label="Location"
              value={location}
              helperText={error?.errors?.location}
              error={!!error?.errors?.location}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6" lg="3">
          <FormControlLabel
            control={<Switch checked={isVerified} onChange={handleChange} name="isVerified" />}
            label={isVerified ? 'Verified Investor' : 'Unverified Investor'}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <Col md="4">
          <Button
            className="btn btn-primary btn-block waves-effect waves-light"
            type="submit"
            variant="contained"
            color="primary"
            disabled={apiLoading}
          >
            {isEditMode ? 'Edit Investor' : 'Add Investor'}
            {apiLoading && <CircularProgress size={30} />}
          </Button>
        </Col>
      </div>
    </Form>
  );
}

export default InvestorForm;
