import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { isSameMonth, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

// import { isSameMonth } from '../../helpers/dateHelper';
function MiniWidget(props) {
  const { users, companies, founders, investors } = props;
  const today = new Date();
  const usersThisMonth = users.filter((user) => isSameMonth(today, parseISO(user.createdAt)));
  const userDelta =
    usersThisMonth.length === users.length
      ? 0
      : Math.round((usersThisMonth.length / (users.length - usersThisMonth.length)) * 100);

  const foundersThisMonth = founders.filter((user) => isSameMonth(today, parseISO(user.createdAt)));
  const founderDelta =
    foundersThisMonth.length === founders.length
      ? 0
      : Math.round((foundersThisMonth.length / (founders.length - foundersThisMonth.length)) * 100);

  const companiesThisMonth = companies.filter((user) => isSameMonth(today, parseISO(user.createdAt)));
  const companyDelta =
    companiesThisMonth.length === companies.length
      ? 0
      : Math.round((companiesThisMonth.length / (companies.length - companiesThisMonth.length)) * 100);

  const investorsThisMonth = investors.filter((user) => isSameMonth(today, parseISO(user.createdAt)));
  const investorDelta =
    investorsThisMonth.length === investors.length
      ? 0
      : Math.round((investorsThisMonth.length / (investors.length - investorsThisMonth.length)) * 100);

  const reports = [
    {
      icon: 'fas fa-users',
      title: 'Users',
      link: '/users',
      value: users.length,
      badgeValue: userDelta > 0 ? `+ ${userDelta}%` : `${userDelta}%`,
      color: userDelta > 0 ? 'success' : 'secondary',
      desc: 'From last month',
    },
    {
      icon: 'fas fa-building',
      title: 'Startups',
      link: '/startups',
      value: companies.length,
      badgeValue: companyDelta > 0 ? `+ ${companyDelta}%` : `${companyDelta}%`,
      color: companyDelta > 0 ? 'success' : 'secondary',
      desc: 'From last month',
    },
    {
      icon: 'fas fa-users',
      link: '/founders',
      title: 'Founders',
      value: founders.length,
      badgeValue: founderDelta > 0 ? `+ ${founderDelta}%` : `${founderDelta}%`,
      color: founderDelta > 0 ? 'success' : 'secondary',
      desc: 'From last month',
    },
    {
      icon: 'mdi mdi-account-cash-outline',
      title: 'Investors',
      link: '/investors',
      value: investors.length,
      badgeValue: investorDelta > 0 ? `+ ${investorDelta}%` : `${investorDelta}%`,
      color: investorDelta > 0 ? 'success' : 'secondary',
      desc: 'From last month',
    },
  ];
  return (
    <React.Fragment>
      {reports.map((report, key) => (
        <Col sm="6" lg="3" key={key}>
          <Link to={report.link}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i className={report.icon}></i>
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {report.value} <i className="mdi mdi-chevron-up ml-1 text-success"></i>
                  </h4>
                  <div className="d-flex">
                    <span className={'badge badge-soft-' + report.color + ' font-size-12'}> {report.badgeValue} </span>{' '}
                    <span className="ml-2 text-truncate">{report.desc}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
      ))}
    </React.Fragment>
  );
}

export default MiniWidget;
