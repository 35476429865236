import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import founderService from '../../../services/founderService';
import validationHelper from '../../../helpers/validationHelper';
import FounderForm from '../../../components/Secondary/FounderForm';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  imageUrl: '',
  companies: [],
  previousCompanies: [],
  birthDate: '',
  isVerified: false,
  websiteURL: '',
  twitterURL: '',
  instagramURL: '',
  linkedInURL: '',
  mediumURL: '',
  substackURL: '',
};

function AddFounder(props) {
  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [userInput, setUserInput] = useState(initialState);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await founderService.addFounder(trimmedInput);
      setUserInput(initialState);
      toast.success(response.message);
    } catch (error) {
      error.message && toast.success(error.message);
      setError(error);
    }
    setApiLoading(false);
  }

  const setImageInputError = (error) => {
    setError({ errors: { imageUrl: error } });
  };

  const updateImageUrl = (url) => {
    setUserInput((prevValue) => ({ ...prevValue, imageUrl: url }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Founders" subSection="Add Founder" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <FounderForm
                      inputValues={userInput}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      error={error}
                      onImageUrlChange={updateImageUrl}
                      apiLoading={apiLoading}
                      onImageError={setImageInputError}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(AddFounder);
