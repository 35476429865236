import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import differenceInYears from 'date-fns/differenceInYears';
import parseISO from 'date-fns/parseISO';

export const formatDate = format;
export const timeFromNow = formatDistanceToNow;
// export const getAge = differenceInYears;
export const baseDateFormat = formatDistanceStrict;

export function getAge(date) {
  const now = new Date();
  const age = parseISO(date);
  const years = differenceInYears(now, age);
  // age = `${years} years old`;

  return years;
}
