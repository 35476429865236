import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// users
import authenticationService from '../../../services/authenticationService';
import { deleteAuthenticatedUser } from '../../../store/actions';
import defaultAvatar from '../../../assets/images/avatar.png';

const ProfileMenu = (props) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const { deleteAuthenticatedUser, authenticatedUser: user, history } = props;

  const handleLogout = () => {
    try {
      authenticationService.logOut();
      deleteAuthenticatedUser();
      history.push('/login');
    } catch (error) {}
  };

  return (
    <React.Fragment>
      {user && (
        <Dropdown
          isOpen={dropDownVisible}
          toggle={() => setDropDownVisible(!dropDownVisible)}
          className="d-inline-block"
        >
          <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
            <img
              className="rounded-circle header-profile-user"
              src={user.imageUrl || defaultAvatar}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {user.firstName} {user.lastName}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem tag="a" href="#">
              <i className="bx bx-user font-size-16 align-middle mr-1"></i>
              Profile
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <span className="badge badge-success float-right">11</span>
              <i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>
              Settings
            </DropdownItem>
            <DropdownItem tag="a" href="auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
              Lock screen
            </DropdownItem> */}
            {/* <div className="dropdown-divider"></div> */}
            <button className="dropdown-item" onClick={handleLogout}>
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>Logout</span>
            </button>
          </DropdownMenu>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { authenticatedUser } = state.User;
  return { authenticatedUser };
};

const mapDispatchToProps = { deleteAuthenticatedUser };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileMenu));
