const validationHelper = {
  trimValues(values) {
    const newValues = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value || typeof value === 'boolean') {
        newValues[key] = typeof value === 'string' ? value.trim() : value;
      }
    });
    return newValues;
  },
  investorTypeMapper: {
    VENTURE_CAPITAL: 'Venture Capital',
    ACCELERATOR: 'Accelerator',
    INCUBATOR: 'Incubator',
    ANGEL_INVESTOR: 'Angel Investor',
  },
  fundingStagesMapper: {
    PRE_SEED: 'Pre Seed',
    SEED: 'Seed',
    Series_A: 'Series A',
    Series_B: 'Series B',
    Series_C: 'Series C',
    Series_D: 'Series D',
    Series_E: 'Series E',
    Series_F: 'Series F',
  },
};
export default validationHelper;
