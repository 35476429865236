import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const ConfirmDelete = (props) => {
  const { confirmBtnText, onCancel, onConfirm, message } = props;
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText={confirmBtnText}
      confirmBtnBsStyle="danger"
      title="Are you sure?"
      onConfirm={onConfirm}
      onCancel={onCancel}
      focusCancelBtn
    >
      {message}
    </SweetAlert>
  );
};

export default ConfirmDelete;
