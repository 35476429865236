import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import fundingRoundService from '../../../services/fundingRoundService';
import validationHelper from '../../../helpers/validationHelper';
import FundingRoundForm from './FundingRoundForm';

const initialState = {
  title: '',
  companyId: '',
  type: '',
  amount: '',
  investors: [],
  dateFunded: '',
};

function FundingRound(props) {
  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [userInput, setUserInput] = useState(initialState);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await fundingRoundService.addFundingRound(trimmedInput);
      setUserInput({ ...initialState });
      toast.success(response.message);
    } catch (error) {
      error?.message && toast.success(error.message);
      setError(error);
    }
    setApiLoading(false);
  }

  // const setImageInputError = (error) => {
  //   setError({ errors: { logoUrl: error } });
  // };

  // const updateImageUrl = (url) => {
  //   setUserInput((prevValue) => ({ ...prevValue, logoUrl: url }));
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Funding Rounds" subSection="Add Funding Rounds" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div>
                    <FundingRoundForm
                      inputValues={userInput}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      error={error}
                      apiLoading={apiLoading}
                      // onImageUrlChange={updateImageUrl}
                      // onImageError={setImageInputError}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default FundingRound;
