import React, { useEffect } from 'react';
import MetisMenu from 'metismenujs';
import { Link, withRouter } from 'react-router-dom';

const SidebarContent = (props) => {
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menu </li>
          <li>
            {/* <Link to="/#" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              <span className="badge badge-pill badge-info float-right">03</span>
              <span>Dashboard</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/dashboard">Default</Link>
              </li>
            </ul> */}
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/users" className="waves-effect">
              <i className="fas fa-users"></i>
              <span>Users</span>
            </Link>
          </li>
          <li>
            <Link to="/#" className="waves-effect">
              <i className="fas fa-building"></i>
              <span>Startups</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/startups">View Startups</Link>
              </li>
              <li>
                <Link to="/startups/add">Add Startup</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/#" className="waves-effect">
              <i className="bx bx-user-plus"></i>
              <span>Founders</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/founders">View Founders</Link>
              </li>
              <li>
                <Link to="/founders/add">Add Founder</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/#" className="waves-effect">
              <i className="mdi mdi-account-cash-outline"></i>
              <span>Investors</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/investors">View Investors</Link>
              </li>
              <li>
                <Link to="/investors/add">Add Investor</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/#" className="waves-effect">
              <i className="fas fa-dollar-sign"></i>
              <span>Funding Rounds</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/funding-rounds">View Funding Rounds</Link>
              </li>
              <li>
                <Link to="/funding-rounds/add">Add Funding Round</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/bulk-upload" className="waves-effect">
              <i className="fas fa-upload"></i>
              <span>Bulk Upload</span>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
