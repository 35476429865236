import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import investorService from '../../../services/investorService';
import validationHelper from '../../../helpers/validationHelper';
import InvestorForm from './InvestorForm';

const initialState = {
  name: '',
  type: '',
  fundingCapacity: '',
  fundingStages: '',
  logoUrl: '',
  location: '',
  isVerified: false,
  numberOfExits: '',
  // location: '',
  companiesFunded: [],
};

function EditCompany(props) {
  const { history } = props;
  const { investorId } = useParams();

  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userInput, setUserInput] = useState(initialState);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  async function getInvestor(id) {
    setUserInput(true);
    try {
      const response = (await investorService.getInvestor(id)).data;
      const { investor } = response;
      const companiesFunded = investor.companiesFunded ? investor.companiesFunded.map((fr) => fr.id) : [];
      setUserInput({
        ...investor,
        companiesFunded,
      });
    } catch (err) {
      setError({ message: err.message || 'Error getting investor' });
    }
    setInitialLoading(false);
  }

  useEffect(() => {
    getInvestor(investorId);
  }, [investorId]);

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const formattedFundingStages = userInput.fundingStages ? userInput.fundingStages.split(',') : '';
    const trimmedInput = validationHelper.trimValues({ ...userInput, fundingStages: formattedFundingStages });
    try {
      const response = await investorService.updateInvestor(investorId, trimmedInput);
      toast.success(response.message);
      history.push('/investors');
    } catch (error) {
      toast.error(error.message);
      setError(error);
    }
    setApiLoading(false);
  }

  const setImageInputError = (error) => {
    setError({ errors: { logoUrl: error } });
  };

  const updateImageUrl = (url) => {
    setUserInput((prevValue) => ({ ...prevValue, logoUrl: url }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Investors" subSection="Edit Investor" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              {initialLoading && (
                <div className="mb-2 text-center">
                  <i className="bx bx-loader bx-spin  bx-sm"></i>
                </div>
              )}
              {!initialLoading && (
                <Card>
                  <CardBody>
                    <div>
                      <InvestorForm
                        inputValues={userInput}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        error={error}
                        onImageUrlChange={updateImageUrl}
                        apiLoading={apiLoading}
                        onImageError={setImageInputError}
                        isEditMode={true}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(EditCompany);
