import { SET_AUTHENTICATED_USER, DELETE_AUTHENTICATED_USER } from './actionTypes';

const initialState = {
  isAuthenticated: false,
  authenticatedUser: {},
  authToken: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        authenticatedUser: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        authToken: action.payload.authToken,
      };
    case DELETE_AUTHENTICATED_USER:
      return initialState;

    default:
      return state;
  }
};

export default login;
