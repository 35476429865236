import axios from 'axios';

import config from '../config';

const axiosInstance = axios.create({
  baseURL: config.baseApiURL,
  timeout: 5000,
});

export default axiosInstance;
