import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';

import MiniWidget from './MiniWidget';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import userService from '../../services/userService';
import investorService from '../../services/investorService';
import companyService from '../../services/companyService';
import founderService from '../../services/founderService';

import './css/dashboard.scss';

const Dashboard = (props) => {
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [founders, setFounders] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);

  // const [error, setError] = useState({});

  async function getData(query, limit, page) {
    try {
      setApiLoading(true);
      const userPromise = userService.getUsers({ all: true });
      const companyPromise = companyService.getCompanies({ all: true });
      const founderPromise = founderService.getFounders({ all: true });
      const investorPromise = investorService.getInvestors({ all: true });

      const [userResponse, companyResponse, founderResponse, investorResponse] = await Promise.all([
        userPromise,
        companyPromise,
        founderPromise,
        investorPromise,
      ]);

      const { users } = userResponse.data;
      const { companies } = companyResponse.data;
      const { founders } = founderResponse.data;
      const { investors } = investorResponse.data;

      setUsers(users);
      setCompanies(companies);
      setFounders(founders);
      setInvestors(investors);
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error fetching data');
    }
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Dashboard" subSection="Dashboard" />
          <div className="mb-2 text-center">{apiLoading && <i className="bx bx-loader bx-spin  bx-sm"></i>}</div>
          {!apiLoading && (
            <>
              <Row>
                <MiniWidget users={users} companies={companies} founders={founders} investors={investors} />
              </Row>
            </>
          )}

          <Row>
            <Col xl="4"></Col>
            <Col xl="4"></Col>

            <Col xl="4"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
