import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  CircularProgress,
  TextField,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab';
import { Row, Col, Form } from 'reactstrap';
// import throttle from 'lodash/throttle';

import PhotoUpload from '../../../components/Common/PhotoUpload';
import Switch from '../../../components/Common/Switch';
import { formatDate } from '../../../helpers/dateHelper';
import founderService from '../../../services/founderService';
import investorService from '../../../services/investorService';
import fundingRoundService from '../../../services/fundingRoundService';

import defaultAvatar from '../../../assets/images/defaults/startup.png';

function CompanyForm(props) {
  // const [companyOpen, setCompanyOpen] = React.useState(false);
  const [founderOptions, setFounderOptions] = React.useState([]);
  const [investorOptions, setInvestorOptions] = React.useState([]);
  const [fundingOptions, setFundingOptions] = React.useState([]);
  // const companyLoading = companyOpen && companyOptions.length === 0;

  const { onSubmit, inputValues, onImageUrlChange, error, onImageError, onChange, apiLoading, isEditMode } = props;
  const {
    name,
    isNonProfit,
    sector,
    logoUrl,
    isVerified,
    numberOfEmployees,
    numberOfUsers,
    isFunded,
    fundingRounds,
    founders,
    investors,
    dateFounded,
    isActive,
    isPubliclyTraded,
    location,
    websiteURL,
    facebookURL,
    twitterURL,
    instagramURL,
    linkedInURL,
    mediumURL,
    substackURL,
  } = inputValues;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    onChange({ name, value, type, checked });
  };

  const updateImageUrl = (url) => {
    onImageUrlChange(url);
  };

  const setImageInputError = (error) => {
    onImageError(error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  const getRequiredData = async (query) => {
    const founderPromise = founderService.getFounders({ all: true });
    const investorPromise = investorService.getInvestors({ all: true });
    const fundingPromise = fundingRoundService.getFundingRounds({ all: true });

    const [founderResponse, investorResponse, fundingResponse] = await Promise.all([
      founderPromise,
      investorPromise,
      fundingPromise,
    ]);
    const { founders } = founderResponse.data;
    const { investors } = investorResponse.data;
    const { fundingRounds } = fundingResponse.data;
    setFounderOptions(founders);
    setFundingOptions(fundingRounds);
    setInvestorOptions(investors);
  };

  useEffect(() => {
    getRequiredData();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <div className="form-group">
            <PhotoUpload
              uploadType="company"
              imageUrl={logoUrl}
              defaultAvatar={defaultAvatar}
              name={name}
              updateImageUrl={updateImageUrl}
              onError={setImageInputError}
              errorMessage={error?.errors?.logoUrl}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="name"
              name="name"
              type="text"
              label="Name"
              value={name}
              helperText={error?.errors?.name}
              error={!!error?.errors?.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="sector"
              name="sector"
              type="text"
              label="Sector"
              value={sector}
              helperText={error?.errors?.sector}
              error={!!error?.errors?.sector}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="location"
              name="location"
              type="text"
              label="Location"
              value={location}
              helperText={error?.errors?.location}
              error={!!error?.errors?.location}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="dateFounded"
              name="dateFounded"
              type="date"
              label="Date Founded"
              defaultValue={dateFounded ? undefined : '2017-05-24'}
              value={dateFounded ? formatDate(new Date(dateFounded), 'yyyy-MM-dd') : undefined}
              helperText={error?.errors?.dateFounded}
              error={!!error?.errors?.dateFounded}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="numberOfUsers"
              name="numberOfUsers"
              type="number"
              label="No. of users"
              value={numberOfUsers}
              helperText={error?.errors?.numberOfUsers}
              error={!!error?.errors?.numberOfUsers}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="numberOfEmployees"
              name="numberOfEmployees"
              type="number"
              label="No. of employees"
              value={numberOfEmployees}
              helperText={error?.errors?.numberOfEmployees}
              error={!!error?.errors?.numberOfEmployees}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <FormControl variant="outlined" fullWidth error={error?.errors?.founders}>
              <InputLabel io="founders">Founders</InputLabel>
              <Select label="founders" id="founders" multiple name="founders" value={founders} onChange={handleChange}>
                <MenuItem value={[]}>
                  <em>None</em>
                </MenuItem>
                {founderOptions?.length > 0 &&
                  founderOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.firstName} ${option.lastName}`}
                    </MenuItem>
                  ))}
              </Select>
              {error?.errors?.founders && <FormHelperText>{error?.errors?.founders}</FormHelperText>}
            </FormControl>
            {/* <FormControl variant="outlined" fullWidth>
              <Autocomplete
                label="founders"
                id="founders"
                multiple
                name="founders"
                value={founders}
                getOptionDisabled={(option) => (option === founderOptions.length) === 0}
                // defaultValue={founders}
                onChange={(event, newValue) => {
                  const parsedValue = newValue.founders ? newValue.founders.map((founder) => founder.id) : [];
                  onChange({ name: 'founders', value: parsedValue });
                }}
                options={founderOptions}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Founders" placeholder="Founders" />
                )}
                // renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
              />
            </FormControl> */}
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <FormControl variant="outlined" fullWidth error={error?.errors?.investors}>
              <InputLabel id="investors">Investors</InputLabel>
              <Select
                label="investors"
                id="investors"
                multiple
                name="investors"
                value={investors}
                onChange={handleChange}
              >
                <MenuItem value={[]}>
                  <em>None</em>
                </MenuItem>
                {investorOptions?.length > 0 &&
                  investorOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
              {error?.errors?.investors && <FormHelperText>{error?.errors?.investors}</FormHelperText>}
            </FormControl>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <FormControl variant="outlined" fullWidth error={error?.errors?.fundingRounds}>
              <InputLabel io="fundingRounds">Funding Rounds</InputLabel>
              <Select
                label="fundingRounds"
                id="fundingRounds"
                multiple
                name="fundingRounds"
                value={fundingRounds}
                onChange={handleChange}
              >
                <MenuItem value={[]}>
                  <em>None</em>
                </MenuItem>
                {founderOptions?.length > 0 &&
                  fundingOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.title}`}
                    </MenuItem>
                  ))}
              </Select>
              {error?.errors?.['fundingRounds[0]'] && <FormHelperText>Invalid funding round supplied</FormHelperText>}
            </FormControl>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="websiteURL"
              name="websiteURL"
              type="url"
              label="Website"
              placeholder="https://johndoe.com"
              value={websiteURL}
              helperText={error?.errors?.websiteURL}
              error={!!error?.errors?.websiteURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="facebookURL"
              name="facebookURL"
              type="url"
              label="facebook Account"
              placeholder="https://facebook.com/jdoe"
              value={facebookURL}
              helperText={error?.errors?.facebookURL}
              error={!!error?.errors?.facebookURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="linkedInURL"
              name="linkedInURL"
              type="url"
              label="LinkedIn"
              value={linkedInURL}
              placeholder="https://linkedin.com/jdoe"
              helperText={error?.errors?.linkedInURL}
              error={!!error?.errors?.linkedInURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="twitterURL"
              name="twitterURL"
              type="url"
              label="Twitter account"
              placeholder="https://twitter.com/jdoe"
              value={twitterURL}
              helperText={error?.errors?.twitterURL}
              error={!!error?.errors?.twitterURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="instagramURL"
              name="instagramURL"
              type="url"
              label="Instagram page"
              value={instagramURL}
              placeholder="https://instagram.com/jdoe"
              helperText={error?.errors?.instagramURL}
              error={!!error?.errors?.instagramURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="mediumURL"
              name="mediumURL"
              type="url"
              label="medium page"
              placeholder="https://medium.com/jdoe"
              value={mediumURL}
              helperText={error?.errors?.mediumURL}
              error={!!error?.errors?.mediumURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="substackURL"
              name="substackURL"
              type="url"
              label="Substack"
              placeholder="https://jdoe.substack.com"
              value={substackURL}
              helperText={error?.errors?.substackURL}
              error={!!error?.errors?.substackURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6" lg="4">
          <FormControlLabel
            control={<Switch checked={isPubliclyTraded} onChange={handleChange} name="isPubliclyTraded" />}
            label={isPubliclyTraded ? 'Publicly traded company: Yes' : 'Publicly traded company: No'}
          />
        </Col>
        <Col md="6" lg="4">
          <FormControlLabel
            control={<Switch checked={isNonProfit} onChange={handleChange} name="isNonProfit" />}
            label={isNonProfit ? 'Non profit' : 'For profit'}
          />
        </Col>
        <Col md="6" lg="4">
          <FormControlLabel
            control={<Switch checked={isVerified} onChange={handleChange} name="isVerified" />}
            label={isVerified ? 'verified account' : 'regular account'}
          />
        </Col>
        <Col md="6" lg="4">
          <FormControlLabel
            control={<Switch checked={isActive} onChange={handleChange} name="isActive" />}
            label={isActive ? 'Active' : 'Inactive'}
          />
        </Col>
        <Col md="6" lg="4">
          <FormControlLabel
            control={<Switch checked={isFunded} onChange={handleChange} name="isFunded" />}
            label={isFunded ? 'Funded' : 'Not Funded'}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <Col md="4">
          <Button
            className="btn btn-primary btn-block waves-effect waves-light"
            type="submit"
            variant="contained"
            color="primary"
            disabled={apiLoading || !name || !location}
          >
            {isEditMode ? 'Edit Startup' : 'Add Startup'}
            {apiLoading && <CircularProgress size={30} />}
          </Button>
        </Col>
      </div>
    </Form>
  );
}

export default CompanyForm;
