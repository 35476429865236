import API from './API';
import errorHandler from '../helpers/errorHandler';

export default {
  getFundingRounds({ query = undefined, limit = 10, page = 1, all = false }) {
    let queryPromise;
    if (query) {
      queryPromise = API.get(`/v1/funding?q=${query}&limit=${limit}&page=${page}`);
    } else if (all) {
      queryPromise = API.get('/v1/funding?all=true');
    } else {
      queryPromise = API.get(`/v1/funding?limit=${limit}&page=${page}`);
    }
    return queryPromise
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  getFundingRound(id) {
    return API.get(`/v1/funding/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  updateFundingRound(id, data) {
    return API.put(`/v1/funding/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  deleteFundingRound(id, data) {
    return API.delete(`/v1/funding/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  addFundingRound(fundingData) {
    return API.post('/v1/funding', fundingData)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
};
