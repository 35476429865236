import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Pagination from '../../../components/Common/Pagination';
import ConfirmDelete from '../../../components/Common/ConfirmDelete';

import fundingRoundService from '../../../services/fundingRoundService';
import { formatDate } from '../../../helpers/dateHelper';

function FundingRounds(props) {
  const [fundingRounds, setFundingRounds] = useState([]);
  const [fundingRound, setFundingRound] = useState({});

  const [query, setQuery] = useState('');
  const [paginationData, setPaginationData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [confirmDelete, setconfirmDelete] = useState(false);

  async function getFundingRounds(query, limit, page) {
    try {
      setApiLoading(true);
      const response = (await fundingRoundService.getFundingRounds({ query, limit, page })).data;
      setFundingRounds(response.fundingRounds);
      setPaginationData(response.metaData);
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error Fetching Investors');
    }
  }
  useEffect(() => {
    getFundingRounds(query, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationClick = (page) => {
    getFundingRounds(query, limit, page);
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setLimit(value);
    getFundingRounds(query, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getFundingRounds(query, limit);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleOnDelete = (investor) => {
    setFundingRound(investor);
    setconfirmDelete(true);
  };

  const handleDelete = async (event) => {
    try {
      setApiLoading(true);
      await fundingRoundService.deleteFundingRound(fundingRound.id);
      toast.success(`investor ${fundingRound.title} deleted successfully`);
      setApiLoading(false);
      getFundingRounds(query, limit);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error deleting Investor');
    }
    setconfirmDelete(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Funding Rounds" subSection="All Funding Rounds" />
          {confirmDelete && (
            <ConfirmDelete
              confirmBtnText={`Delete Funding Round`}
              onConfirm={handleDelete}
              onCancel={() => setconfirmDelete(false)}
              message={`You are about to delete ${fundingRound.title}`}
            />
          )}
          <Row>
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="form-group m-0">
                <div className="input-group">
                  <input
                    type="search"
                    value={query}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Search funding rounds ..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">
                      <i className="mdi mdi-magnify"></i>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <label htmlFor="search" className="col-form-label" style={{ margin: '0 5px 0 10px' }}>
                      Limit
                    </label>
                    <select id="search" value={limit} onChange={handleLimitChange} className="form-control">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-2 text-center">
                    {apiLoading && <i className="bx bx-loader bx-spin  bx-sm"></i>}
                  </div>
                  <div className="table-responsive">
                    {paginationData?.pageCount > 0 && (
                      <Row>
                        <Col lg="12">
                          <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                        </Col>
                      </Row>
                    )}
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Title</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date Funded</th>
                          <th scope="col">Funding Capacity</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fundingRounds?.length > 0 &&
                          fundingRounds.map((fundingRound) => (
                            <tr key={fundingRound.id}>
                              <td>
                                <span>{fundingRound.id}</span>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{fundingRound.title}</h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{fundingRound.type}</h5>
                              </td>

                              <td>
                                {fundingRound.dateFunded &&
                                  formatDate(new Date(fundingRound.dateFunded), 'MMMM dd, yyyy')}
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{`$${fundingRound.amount}`}</h5>
                              </td>
                              <td>
                                <ul className="list-inline font-size-20 contact-links mb-0">
                                  <li className="list-inline-item px-2">
                                    <Link
                                      to={`/funding-rounds/edit/${fundingRound.id}`}
                                      id={'company' + fundingRound.id}
                                    >
                                      <i className="bx bx-edit"></i>
                                      <UncontrolledTooltip placement="top" target={'company' + fundingRound.id}>
                                        Edit
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item px-2">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a onClick={() => handleOnDelete(fundingRound)}>
                                      <i className="bx bx-trash"></i>

                                      <UncontrolledTooltip placement="top" target={'company' + fundingRound.id}>
                                        Edit
                                      </UncontrolledTooltip>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        {fundingRounds?.length === 0 && !apiLoading && (
                          <tr>
                            <th colSpan="8" className="text-center">
                              no Funding Rounds found
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {paginationData?.pageCount > 0 && (
                    <Row>
                      <Col lg="12">
                        <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(FundingRounds);
