import React from 'react';
import ms from 'ms';
import Uppy from '@uppy/core';
import { StatusBar } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';
import { v4 as uuidv4 } from 'uuid';

import CameraIcon from './CameraIcon';
import './PhotoUpload.scss';
import uploadService from '../../services/uploadService';

export default class ProfilePictureUpload extends React.Component {
  fileInputRef = React.createRef();

  fileRef = React.createRef();

  uppy = Uppy({
    id: 'uppyId',
    debug: true,
    autoProceed: false,
    allowMultipleUploads: false,
    restrictions: {
      maxFileSize: 512000, // 500KB in Bytes
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.png', '.jpg', '.jpeg'],
      // allowedFileTypes: ['.png'],
    },
  });

  state = {
    uploadError: '',
  };

  componentDidMount() {
    const { name, uploadType } = this.props;

    if (this.uppy) {
      this.uppy
        .use(ThumbnailGenerator, {
          waitForThumbnailsBeforeUpload: false,
        })
        .use(AwsS3, {
          metaFields: ['name'],
          timeout: ms('1 minute'),
          limit: 1,
          getUploadParameters(file) {
            const fileName = file.name;
            const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);
            return uploadService
              .getSignedUrl({
                fileName: `${name || uuidv4()}-${Date.now()}`,
                fileType: file.type,
                uploadType: uploadType || 'image',
                fileExt,
              })
              .then((response) => {
                const { data } = response;
                return {
                  method: 'PUT',
                  url: data.data.signedRequestUrl,
                  headers: {
                    'content-type': file.type,
                  },
                };
              });
          },
          getResponseData(_, xhr) {
            if (!xhr.responseURL) {
              return { location: null };
            }

            return { location: xhr.responseURL.replace(/\?.*$/, '') };
          },
        });

      this.uppy.on('thumbnail:generated', (_, preview) => {
        if (this.fileRef.current) {
          this.fileRef.current.src = preview;
        }
      });

      this.uppy.on('upload-success', (_, data) => {
        const { updateImageUrl, setUppyInstance } = this.props;

        updateImageUrl(data.uploadURL);
        if (setUppyInstance) {
          setUppyInstance(null);
        }
      });

      this.uppy.on('upload-error', () => {
        const { onError } = this.props;
        this.setState({
          uploadError: 'File upload was not successful, please try again.',
        });
        onError('File upload was not successful, please try again.');
      });
    }
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  handleFileChange = async (event) => {
    const { onError, setUppyInstance } = this.props;
    const target = event.target;
    const files = Array.from(target.files);
    this.uppy.cancelAll();

    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        });
        if (setUppyInstance) {
          setUppyInstance(this.uppy);
        }
      } catch (err) {
        onError(err.message);

        this.setState({
          uploadError: err.message,
        });
      }
    });

    await this.uppy.upload();
  };

  handleWrapperClick = () => {
    const { onError } = this.props;

    onError('');
    this.setState({
      uploadError: '',
    });

    if (this.fileInputRef?.current) {
      this.fileInputRef.current.click();
    }
  };

  render() {
    const { imageUrl, errorMessage, defaultAvatar } = this.props;
    const { uploadError } = this.state;

    return (
      <div>
        <input
          ref={this.fileInputRef}
          className="sr-only"
          type="file"
          id="my-file-input"
          // accept=".png"
          accept=".png, .jpg, .jpeg"
          onChange={this.handleFileChange}
        />
        <div className="image-wrapper" aria-hidden="true" onClick={this.handleWrapperClick}>
          <img ref={this.fileRef} src={imageUrl || defaultAvatar} className="image" alt="Your profile" />
          <div className="image-overlay" />
          <div className="icon-overlay">
            <CameraIcon width={18} height={14} />
          </div>
          )}
        </div>
        <div className="mb-2">
          {!uploadError && !errorMessage && <small className="text-muted">Image should be at most 500KB</small>}
          {(uploadError || errorMessage) && <small className="text-danger">{uploadError || errorMessage}</small>}
        </div>
        <StatusBar
          uppy={this.uppy}
          hideAfterFinish
          hideUploadButton
          hideCancelButton
          hideRetryButton
          hidePauseResumeButton
          showProgressDetails
        />
      </div>
    );
  }
}
