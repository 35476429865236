import API from './API';
import errorHandler from '../helpers/errorHandler';

export default {
  getCompanies({ query = undefined, limit = 10, page = 1, all = false }) {
    let queryPromise;
    if (query) {
      queryPromise = API.get(`/v1/companies?q=${query}&limit=${limit}&page=${page}`);
    } else if (all) {
      queryPromise = API.get('/v1/companies?all=true');
    } else {
      queryPromise = API.get(`/v1/companies?limit=${limit}&page=${page}`);
    }
    return queryPromise
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  getCompany(id) {
    return API.get(`/v1/companies/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  updateCompany(id, data) {
    return API.put(`/v1/companies/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  addCompany(founderData) {
    return API.post('/v1/companies', founderData)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  deleteCompany(id, data) {
    return API.delete(`/v1/companies/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
};
