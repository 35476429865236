const authorizationHelper = {
  isSuperAdmin(role) {
    if (!role) {
      return false;
    }
    return role === 'SUPER_ADMIN';
  },
  isAdmin(role) {
    if (!role) {
      return false;
    }
    return role === 'ADMIN';
  },
  isAtLeastAdmin(role) {
    const authorizedRoles = ['ADMIN', 'SUPER_ADMIN'];
    if (!role) {
      return false;
    }
    return authorizedRoles.some((authRole) => role === authRole);
  },
  displayRole(role) {
    const roleMapper = {
      USER: 'resigetered user',
      ADMIN: 'Admin',
      SUPER_ADMIN: 'Super Admin',
    };
    return roleMapper[role] || role;
  },
};
export default authorizationHelper;
