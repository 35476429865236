import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Table, Alert } from 'reactstrap';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Pagination from '../../../components/Common/Pagination';

import userService from '../../../services/userService';
import authorizationHelper from '../../../helpers/authorizationHelper';
import { timeFromNow } from '../../../helpers/dateHelper';

function Users(props) {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationData, setPaginationData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [error, setError] = useState({});

  async function getUsers(query, limit, page) {
    try {
      setApiLoading(true);
      const response = (await userService.getUsers({ query, limit, page })).data;
      setUsers(response.users);
      setPaginationData(response.metaData);
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      setError({ message: err.message || 'Error Fetching Users' });
    }
  }
  useEffect(() => {
    getUsers(query, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationClick = (page) => {
    getUsers(query, limit, page);
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setLimit(value);
    getUsers(query, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getUsers(query, limit);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Dashboard" subSection="Users" />
          {error.message && (
            <Alert color="danger" style={{ marginTop: '13px' }}>
              {error.message}
            </Alert>
          )}
          <Row>
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="form-group m-0">
                <div className="input-group">
                  <input
                    type="search"
                    value={query}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Search users ..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">
                      <i className="mdi mdi-magnify"></i>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <label htmlFor="search" className="col-form-label" style={{ margin: '0 5px 0 10px' }}>
                      Limit
                    </label>
                    <select id="search" value={limit} onChange={handleLimitChange} className="form-control">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-2 text-center">
                    {apiLoading && <i className="bx bx-loader bx-spin  bx-sm"></i>}
                  </div>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: '70px' }}>
                            Avatar
                          </th>
                          <th scope="col">FirstName</th>
                          <th scope="col">LastName</th>
                          <th scope="col">Email</th>
                          <th scope="col">username</th>
                          <th>Role</th>
                          <th>Last logged In</th>
                          {/* <th scope="col">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {users?.length > 0 &&
                          users.map((user) => (
                            <tr key={user.id}>
                              <td>
                                {!user.imageUrl ? (
                                  <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle">
                                      {user.firstName.charAt(0).toUpperCase()}
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <img className="rounded-circle avatar-xs" src={user.imageUrl} alt="" />
                                  </div>
                                )}
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{user.firstName}</h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{user.lastName}</h5>
                              </td>
                              <td>{user.email}</td>
                              <td>{user.username}</td>
                              <td>{authorizationHelper.displayRole(user.role)}</td>
                              <td>{timeFromNow(new Date(user.lastLoginDate), { addSuffix: true })}</td>
                              {/* <td>
                                <ul className="list-inline font-size-20 contact-links mb-0">
                                  <li className="list-inline-item px-2">
                                    <Link to="#" id={'profile' + user.id}>
                                      <i className="bx bx-user-circle"></i>
                                      <UncontrolledTooltip placement="top" target={'profile' + user.id}>
                                        Profile
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                </ul>
                              </td> */}
                            </tr>
                          ))}
                        {users?.length === 0 && !apiLoading && (
                          <tr>
                            <th colSpan="8" className="text-center">
                              no users found
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {paginationData?.pageCount > 0 && (
                    <Row>
                      <Col lg="12">
                        <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { authenticatedUser } = state.User;
  return { authenticatedUser };
};

export default withRouter(connect(mapStateToProps, null)(Users));
