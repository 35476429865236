import API from './API';
import errorHandler from '../helpers/errorHandler';

export default {
  getUsers({ query = undefined, limit = 10, page = 1, all = false }) {
    let queryPromise;
    if (query) {
      queryPromise = API.get(`/v1/users?q=${query}&limit=${limit}&page=${page}`);
    } else if (all) {
      queryPromise = API.get('/v1/users?all=true');
    } else {
      queryPromise = API.get(`/v1/users?limit=${limit}&page=${page}`);
    }
    return queryPromise
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
};
