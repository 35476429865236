import React, { useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

// import images
import formBackgroundImage from '../../assets/images/profile-img.png';
import logo from '../../assets/images/icon-light-background.svg';

import authenticationService from '../../services/authenticationService';

function ForgetPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, SetMessage] = useState({
    success: '',
    error: '',
  });

  // handle input change with keyboard press
  function handleChange(event) {
    const { value } = event.target;
    setEmail(value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const response = await authenticationService.requestPasswordReset({ email, useAdminApp: true });
      SetMessage({ error: '', success: response.message });
      setEmail('');
    } catch (error) {
      SetMessage({ error: error, success: '' });
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Forgot your password?</h5>
                        <p>Enter your Email address</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={formBackgroundImage} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="50" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {message.error && (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {message.error}
                      </Alert>
                    )}
                    {message.success && (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {message.success}
                      </Alert>
                    )}

                    <form className="form-horizontal mt-4" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <TextField
                          id="email"
                          name="email"
                          type="email"
                          label="Email"
                          value={email}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={!email}
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{' '}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{' '}
                </p>
                <p>© {new Date().getFullYear()} Yabalytics</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ForgetPasswordPage;
