import API from './API';
import errorHandler from '../helpers/errorHandler';

export default {
  getFounders({ query = undefined, limit = 10, page = 1, all = false }) {
    let queryPromise;
    if (query) {
      queryPromise = API.get(`/v1/founders?q=${query}&limit=${limit}&page=${page}`);
    } else if (all) {
      queryPromise = API.get('/v1/founders?all=true');
    } else {
      queryPromise = API.get(`/v1/founders?limit=${limit}&page=${page}`);
    }
    return queryPromise
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  getFounder(id) {
    return API.get(`/v1/founders/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  updateFounder(id, data) {
    return API.put(`/v1/founders/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  deleteFounder(id, data) {
    return API.delete(`/v1/founders/${id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
  addFounder(founderData) {
    return API.post('/v1/founders', founderData)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler.handleAPIError);
  },
};
