import React from 'react';

const CameraIcon = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 18 14" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M17.111 12.444c0 .86-.696 1.556-1.555 1.556h-14C.696 14 0 13.304 0 12.444V3.89c0-.86.696-1.556 1.556-1.556h3.11L6.223 0h4.667l1.555 2.333h3.112c.859 0 1.555.697 1.555 1.556v8.555z" />
      <circle cx={8.556} cy={7.778} r={3.111} />
    </g>
  </svg>
);

export default CameraIcon;
