import React, { PureComponent } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default class PaginationContainer extends PureComponent {
  handleClick = (to, currentPage) => {
    const { onPaginationClick } = this.props;
    if (to === currentPage) return;
    onPaginationClick(to);
  };
  render() {
    const {
      data: { pageCount, pageNumber },
    } = this.props;
    const previousItem = pageNumber - 1;
    const currentItem = pageNumber;
    const nextItem = pageNumber + 1;
    return (
      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
        <PaginationItem disabled={pageNumber === 1}>
          <PaginationLink previous onClick={() => this.handleClick(pageNumber - 1, pageNumber)} />
        </PaginationItem>
        {previousItem - 1 > 0 && (
          <PaginationItem key={`${previousItem - 1}-pageItem`}>
            <PaginationLink onClick={() => this.handleClick(previousItem - 1, pageNumber)}>
              {previousItem - 1}
            </PaginationLink>
          </PaginationItem>
        )}
        {previousItem > 0 && (
          <PaginationItem key={`${previousItem}-pageItem`}>
            <PaginationLink onClick={() => this.handleClick(previousItem, pageNumber)}>{previousItem}</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem active key={`${currentItem}-pageItem`}>
          <PaginationLink disabled>{currentItem}</PaginationLink>
        </PaginationItem>

        {nextItem <= pageCount && (
          <PaginationItem key={`${nextItem}-pageItem`}>
            <PaginationLink onClick={() => this.handleClick(nextItem, pageNumber)}>{nextItem}</PaginationLink>
          </PaginationItem>
        )}
        {nextItem + 1 <= pageCount && (
          <PaginationItem key={`${nextItem + 1}-pageItem`}>
            <PaginationLink onClick={() => this.handleClick(nextItem + 1, pageNumber)}>{nextItem + 1}</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem disabled={pageNumber === pageCount}>
          <PaginationLink next onClick={() => this.handleClick(pageNumber + 1, pageNumber)} />
        </PaginationItem>
      </Pagination>
    );
  }
}
