import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Pagination from '../../../components/Common/Pagination';
import ConfirmDelete from '../../../components/Common/ConfirmDelete';

import investorService from '../../../services/investorService';
import validationHelper from '../../../helpers/validationHelper';

function Investors(props) {
  const [investors, setInvestors] = useState([]);
  const [investor, setInvestor] = useState({});
  const [query, setQuery] = useState('');
  const [paginationData, setPaginationData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [confirmDelete, setconfirmDelete] = useState(false);

  async function getInvestors(query, limit, page) {
    try {
      setApiLoading(true);
      const response = (await investorService.getInvestors({ query, limit, page })).data;
      setInvestors(response.investors);
      setPaginationData(response.metaData);
      setApiLoading(false);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error Fetching Investors');
    }
  }
  useEffect(() => {
    getInvestors(query, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationClick = (page) => {
    getInvestors(query, limit, page);
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setLimit(value);
    getInvestors(query, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getInvestors(query, limit);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleOnDelete = (investor) => {
    setInvestor(investor);
    setconfirmDelete(true);
  };

  const handleDelete = async (event) => {
    try {
      setApiLoading(true);
      await investorService.deleteInvestor(investor.id);
      toast.success(`investor ${investor.name} deleted successfully`);
      setApiLoading(false);
      getInvestors(query, limit);
    } catch (err) {
      setApiLoading(false);
      toast.error(err.message || 'Error deleting Investor');
    }
    setconfirmDelete(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Investors" subSection="All Investors" />
          {confirmDelete && (
            <ConfirmDelete
              confirmBtnText={`Delete Investor`}
              onConfirm={handleDelete}
              onCancel={() => setconfirmDelete(false)}
              message={`You are about to delete ${investor.name}`}
            />
          )}
          <Row>
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="form-group m-0">
                <div className="input-group">
                  <input
                    type="search"
                    value={query}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Search investors ..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">
                      <i className="mdi mdi-magnify"></i>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <label htmlFor="search" className="col-form-label" style={{ margin: '0 5px 0 10px' }}>
                      Limit
                    </label>
                    <select id="search" value={limit} onChange={handleLimitChange} className="form-control">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-2 text-center">
                    {apiLoading && <i className="bx bx-loader bx-spin  bx-sm"></i>}
                  </div>
                  {paginationData?.pageCount > 0 && (
                    <Row>
                      <Col lg="12">
                        <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                      </Col>
                    </Row>
                  )}
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Logo</th>
                          <th scope="col">Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Location</th>
                          <th scope="col">Funding Capacity</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {investors?.length > 0 &&
                          investors.map((investor) => (
                            <tr key={investor.id}>
                              <td>
                                <span>{investor.id}</span>
                              </td>
                              <td>
                                {!investor.logoUrl ? (
                                  <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle">
                                      {investor.name.charAt(0).toUpperCase()}
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <img className="rounded-circle avatar-xs" src={investor.logoUrl} alt="" />
                                  </div>
                                )}
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">{investor.name}</h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1">
                                  {validationHelper.investorTypeMapper[investor.type]}
                                </h5>
                              </td>

                              <td>{investor.location}</td>
                              <td>
                                <h5 className="font-size-14 mb-1">{`$${investor.fundingCapacity}`}</h5>
                              </td>
                              <td>
                                <ul className="list-inline font-size-20 contact-links mb-0">
                                  <li className="list-inline-item px-2">
                                    <Link to={`/investors/edit/${investor.id}`} id={'company' + investor.id}>
                                      <i className="bx bx-edit"></i>
                                      <UncontrolledTooltip placement="top" target={'company' + investor.id}>
                                        Edit
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item px-2">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a onClick={() => handleOnDelete(investor)}>
                                      <i className="bx bx-trash"></i>
                                      <UncontrolledTooltip placement="top" target={'company' + investor.id}>
                                        Delete
                                      </UncontrolledTooltip>
                                    </a>
                                  </li>{' '}
                                </ul>
                              </td>
                            </tr>
                          ))}
                        {investors?.length === 0 && !apiLoading && (
                          <tr>
                            <th colSpan="8" className="text-center">
                              no investors found
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {paginationData?.pageCount > 0 && (
                    <Row>
                      <Col lg="12">
                        <Pagination data={paginationData} onPaginationClick={handlePaginationClick} />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Investors);
