import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import investorService from '../../../services/investorService';
import validationHelper from '../../../helpers/validationHelper';
import InvestorForm from './InvestorForm';

const initialState = {
  name: '',
  type: '',
  fundingCapacity: '',
  fundingStages: '',
  logoUrl: '',
  location: '',
  isVerified: false,
  numberOfExits: '',
  // location: '',
  companiesFunded: [],
};

function AddInvestor(props) {
  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [userInput, setUserInput] = useState(initialState);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await investorService.addInvestor(trimmedInput);
      setUserInput({ ...initialState });
      toast.success(response.message);
    } catch (error) {
      setError(error);
      toast.error(error.message);
    }
    setApiLoading(false);
  }

  const setImageInputError = (error) => {
    setError({ errors: { logoUrl: error } });
  };

  const updateImageUrl = (url) => {
    setUserInput((prevValue) => ({ ...prevValue, logoUrl: url }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Investors" subSection="Add Investor" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div>
                    <InvestorForm
                      inputValues={userInput}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      error={error}
                      onImageUrlChange={updateImageUrl}
                      apiLoading={apiLoading}
                      onImageError={setImageInputError}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddInvestor;
