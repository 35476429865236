import { SET_AUTHENTICATED_USER, DELETE_AUTHENTICATED_USER } from './actionTypes';

export const setAuthenticatedUser = (payload) => {
  return {
    type: SET_AUTHENTICATED_USER,
    payload,
  };
};

export const deleteAuthenticatedUser = (history) => {
  return {
    type: DELETE_AUTHENTICATED_USER,
    payload: {
      history,
    },
  };
};
