import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import companyService from '../../../services/companyService';
import validationHelper from '../../../helpers/validationHelper';
import CompanyForm from './CompanyForm';

const initialState = {
  name: '',
  isNonProfit: false,
  sector: '',
  logoUrl: '',
  isVerified: false,
  numberOfEmployees: 0,
  numberOfUsers: 0,
  isFunded: false,
  fundingRounds: [],
  founders: [],
  investors: [],
  dateFounded: '',
  isActive: false,
  isPubliclyTraded: false,
  location: '',
  websiteURL: '',
  facebookURL: '',
  twitterURL: '',
  instagramURL: '',
  linkedInURL: '',
  mediumURL: '',
  substackURL: '',
};

function EditCompany(props) {
  const { history } = props;
  const { companyId } = useParams();

  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userInput, setUserInput] = useState(initialState);

  function handleChange({ name, value, type, checked }) {
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  async function getCompany(id) {
    setUserInput(true);
    try {
      const response = (await companyService.getCompany(id)).data;
      const { company } = response;
      const founders = company.founders ? company.founders.map((founder) => founder.id) : [];
      const investors = company.investors ? company.investors.map((investor) => investor.id) : [];
      const fundingRounds = company.fundingRounds ? company.fundingRounds.map((fr) => fr.id) : [];
      setUserInput({
        ...company,
        founders,
        investors,
        fundingRounds,
        isPubliclyTraded: company.IPOStatus,
        isFunded: company.fundingStatus === 'FUNDED',
        isNonProfit: company.type === 'NON_PROFIT',
      });
    } catch (err) {
      setError({ message: err.message || 'Error Fetching Users' });
    }
    setInitialLoading(false);
  }

  useEffect(() => {
    getCompany(companyId);
  }, [companyId]);

  // handle submit
  async function handleSubmit() {
    setApiLoading(true);
    // remove unfilled input fields that aren't required beecuase the API might through an error
    const trimmedInput = validationHelper.trimValues(userInput);
    try {
      const response = await companyService.updateCompany(companyId, trimmedInput);
      toast.success(response.message);
      history.push('/startups');
    } catch (error) {
      toast.error(error.message);
      setError(error);
    }
    setApiLoading(false);
  }

  const setImageInputError = (error) => {
    setError({ errors: { logoUrl: error } });
  };

  const updateImageUrl = (url) => {
    setUserInput((prevValue) => ({ ...prevValue, logoUrl: url }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Startups" subSection="Edit Startup" />
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              {initialLoading && (
                <div className="mb-2 text-center">
                  <i className="bx bx-loader bx-spin  bx-sm"></i>
                </div>
              )}
              {!initialLoading && (
                <Card>
                  <CardBody>
                    <div>
                      <CompanyForm
                        inputValues={userInput}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        error={error}
                        onImageUrlChange={updateImageUrl}
                        apiLoading={apiLoading}
                        onImageError={setImageInputError}
                        isEditMode={true}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(EditCompany);
